.StationSelection {
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pull-to-refresh {
  position: fixed;
  top: -50px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.7s ease-in-out;
}
.pull-to-refresh.visible {
  top: 0;
}

.StationSelectionForm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  padding: 0 16px 16px;
  border-bottom: 1px solid #ddd;
}
/* ~~~~~~~~~~~~~ALL THE INPUT FIELDS (Dropdown/InputText box) LABELS COMPONENT ~~~~~~~~~~~~~ */
.inputLabels {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: var(--secondaryText) !important;
  margin-top: 33px !important;
  margin-left: 4px !important;
}

.selectStationDropdown {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 8px 0 0 4px !important;
  border-bottom: 1px solid #ddd !important;
}
.selectStationDropdown-error {
  border-bottom: 1px solid #d32f2f !important;
}

/* ~~~~~~~~~~~~~Select Station Component~~~~~~~~~~~~~ */
.selectStationList-container {
  position: fixed;
  /* do not use inset property coz: not supported on old browsers */
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #80808078;
  backdrop-filter: blur(2px);
}
.selectStationList-area {
  position: absolute;
  top: 15%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  max-width: 640px;
  border-radius: 16px 16px 0 0;
  background: #fff;
}
.sliderStationList {
  max-width: 640px !important;
  height: 100%;
}
.selectStationSliderHeader {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: 4px 0 16px !important;
  padding: 16px 16px 0;
}
.stationlistContainer {
  margin: 16px 0 !important;
  height: calc((100vh - 15%) - 177px);
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 16px;
}
.stationNameBlock {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #ddd !important;
  padding: 12px 0 !important;
}
.stationName {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: var(--primaryText) !important;
}
.stationStatus {
  background: var(--light3Blue) !important;
  color: var(--dark1Blue) !important;
  padding: 5px 13px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  border-radius: 4px !important;
}

/* ~~~~~~~~~~~~~Select Station Card Price Component~~~~~~~~~~~~~ */
.stationCardName {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.stationCardClass {
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-top: 4px !important;
}
.stationCardPrice {
  font-weight: bold !important;
  font-size: 14px !important;
}
.remainingTicikets {
  color: var(--light1Blue);
  text-align: right;
  font-weight: 500;
  font-size: 12px;
}
.stationTimeLabel {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.stationTime {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.grayOut {
  color: #777 !important;
}

/* ~~~~~~~~~~~~~Redirect Component~~~~~~~~~~~~~ */
.redirectPageText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 13px 0px 13px;
}

.terms {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: var(--light1Blue);
  text-align: center;
}
.termsCheckbox {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: var(--primaryText);
  text-align: left;
}

.anchor_links {
  color: #1F8FE5;
  font-weight: 700;
  text-decoration: none,
}
