.InputData {
    background: #FFF !important;
}

/* STYLES FOR Accordian COMPONENT */
.accordianLabelText {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: var(--primaryText) !important;
}
.contactSummaryEditText {
    font-weight: 700 !important; 
    font-size: 14px !important; 
    color: var(--dark1Blue);
    text-transform: uppercase;
}
.summaryFilled-primaryText {
    font-weight: 600 !important;
    color:var(--primaryText);
    /*textTransform:"capitalize"*/ 
}
.summaryFilled-secondaryText {
    font-weight: 500 !important;
    color: var(--secondaryText);
}

/* ----------------CONTACT DETAILS---------------- */
.formInputTitles {
    font-weight: 500 !important; 
    font-size: 14px !important;
    color: var(--secondaryText) !important;
}
.filledContact-primaryText {
    font-size: 14px !important;
}
.filledContact-secondaryText {
    font-size: 14px !important;
}

/* ----------------PASSENGER DETAILS---------------- */
.childrenNote {
    border: 1px solid var(--dark2Blue);
    border-radius: 8px;
    font-size: 12px !important;
    color: var(--primaryText);
    background: var(--light3Blue);
    padding: 12px;
    text-align: center;
}
.filledPassenger-primaryText {
    font-size: 12px !important;
    /*textTransform:"capitalize"*/ 
}
.filledPassenger-secondaryText {
    font-size: 12px !important;
    font-weight: 600 !important;
}

/* ----------------SELECTED STATION CARD INFO---------------- */
.selectedTicketCard-header {
    padding: 16px 16px 8px 16px;
    background: #EEF2F6;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
}
.selectedTicketCard-headerIcon {
    color: var(--dark2Blue);
    font-size: 21px !important;
    margin-right: 6px !important;
}
.selectedTicketCard-headerText {
    font-weight: 600 !important;
    font-size: 12px !important;
    color: var(--dark2Blue) !important;
}
.selectedTicketCard-station {
    font-size: 14px !important;
    font-weight: bold !important;
    color: var(--primaryText) !important;
}
.selectedTicketCard-time {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: var(--primaryText) !important;
}
.selectedTicketCard-trainClass {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: var(--secondaryText) !important;
}

/* ----------------PRICE DETAILS---------------- */
.inputSectionLabels {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: var(--primaryText);
}
.priceCard-header {
    font-weight: 500 !important; 
    font-size: 14px !important; 
    color: var(--primaryText);
}
.priceCard-headerPrice {
    font-weight: bold !important;
    font-size: 14px !important;
    color: var(--dark2Blue);
}
.priceCard-detailText {
    font-weight: 600 !important; 
    font-size: 14px !important; 
    color: var(--primaryText);
}
.priceCard-detailPrice {
    font-weight: 700 !important; 
    font-size: 14px !important; 
    color: var(--primaryText);
}