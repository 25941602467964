.submitedPassengerName {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: var(--primaryText);
}
.submitedPassengerID {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: var(--secondaryText);
}

/* ------------------CHANGE SEAT------------------------ */
.changeSeatPassengerDetailCard-container {
    background: var(--light3Blue);
    display: flex;
    align-items: center;
}