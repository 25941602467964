body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 640px;
  margin: 0 auto;
  background-color: #f2f2f2;
  height: 100vh !important;
  overscroll-behavior-y: none;
}

.App {
  background: #fff;
  /* height: 100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primaryBlue: #1f8fe5;
  --dark1Blue: #1078ca;
  --dark2Blue: #00529c;
  --light1Blue: #849fbd;
  --light2Blue: #b7d7ef;
  --light3Blue: #eef2f6;
  --primaryText: #333;
  --secondaryText: #888;
}

/* ~~~~~~~~~~~~~HEADER COMPONENT~~~~~~~~~~~~~ */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: var(--primaryBlue);
}
.headerText {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #fff;
}

/* ~~~~~~~~~~~~~FOOTER COMPONENT~~~~~~~~~~~~~ */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  border-bottom: 2px solid #ed6b23;
}
.footerText {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: var(--primaryText) !important;
  margin-right: 6px !important;
}

/* ~~~~~~~~~~~~~ALL THE SUBMIT BUTTONS ON PAGE END IN BLACK~~~~~~~~~~~~~ */
.themeButton {
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
.themeButtonOutline {
  color: var(--dark1Blue) !important;
  border-color: var(--dark1Blue) !important;
  font-size: 16px !important;
  padding: 8px !important;
  border-radius: 8px !important;
}
.themeButtonContained {
  color: #fff !important;
  background-color: var(--dark1Blue) !important;
  /* padding 10px to maintain height */
  padding: 10px !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 12px 2px rgb(87 151 169 / 25%) !important;
}
.disableSubmitButton {
  color: #fff !important;
  background-color: var(--light2Blue) !important;
  /* padding 10px to maintain height */
  padding: 10px !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
  pointer-events: auto;
}
.themeButtonWhite {
  background: #fff !important;
  font-size: 16px !important;
  padding: 10px !important;
  color: var(--dark1Blue) !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 12px 2px rgb(87 151 169 / 25%) !important;
}

/* ~~~~~~~~~~~~~ALL THE CARDS ON PAGE~~~~~~~~~~~~~ */
.paperCards {
  border-radius: 8px !important;
  box-shadow: 0px 2px 12px 2px rgba(87, 151, 169, 0.12) !important;
  padding: 16px;
}
.dropShadow {
  border-radius: 8px !important;
  box-shadow: 0px 2px 12px 2px rgba(87, 151, 169, 0.12) !important;
}

/* ~~~~~~~~~~~~~ALL THE SELECT INPUTS~~~~~~~~~~~~~ */
.selectInputs {
  height: 40px !important;
  border-radius: 8px !important;
}
.iconArrowInSelectInput {
  margin-right: 12px !important;
  font-size: 24px !important;
  color: #00529c !important;
}

.selectInputDataFont {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: var(--primaryText) !important;
}
.selectInputDataFontError {
  color: #f75656 !important;
}
.errorTextSelectStation {
  color: #f75656;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

/* ~~~~~~~~~~~~~ALL THE HelperText on ERROR fr span~~~~~~~~~~~~~ */
.helperText-error {
  display: flex;
  align-items: center;
}
